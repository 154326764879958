<template>
  <div id="container" v-if="fetch_data">
    <section id="shop">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>お届け先の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">2</div>
            <p>ご注文内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>ご注文を<br class="sp">受け付けました</p>
          </div>
        </div>

        <div class="cts_box form">
          <h2 class="sub_ttl"><span>お届け先の入力</span></h2>
          <h3 class="min_ttl">ご注文内容</h3>
          <div class="cart_box">
            <div class="cart_item flex" v-for="article in fetch_data.shopping.articles"
                 v-bind:key="article.article.id">
              <div class="image"><img v-bind:src="article.article.image_files[0]" alt=""></div>
              <div class="cart_cts">
                <h3 class="item_ttl">{{ article.article.title }}</h3>
                <p class="price">¥ {{ delimited(article.price) }}</p>
                <template v-if="false">
                  <div class="tagbox" v-if="article.article.point_usable"><span class="tag">ポイント払い可能</span></div>
                  <div class="tagbox" v-else><span class="tag">ポイント払い不可</span></div>
                </template>
                <div class="point">ポイント：<span>3%</span>（ポイント利用分には付与されません）</div>
                <div class="item_count flex">
                  <p>数量: {{ article.count }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cart_all flex">
            <div class="count_all">カートの合計（{{ delimited(fetch_data.shopping_count) }}点の商品）（税込）：</div>
            <div class="price_all">¥ {{ delimited(fetch_data.total_price) }}</div>
          </div>
        </div>

        <div class="cts_box mb20 anchor_point" id="form">
          <form @submit="update">
            <h3 class="min_ttl">お届け先をご入力ください</h3>
            <p class="lead_txt">
              下記フォームに必要事項をご入力の上、画面下の「ご注文内容の確認」ボタンを押してください。<br>
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <div class="msg_box error" v-if="errors">
              <h3>ご入力内容にエラー項目があります</h3>
              <p>エラー個所は赤く表示され、エラー理由がすぐ下に赤字で示されます。<br>お手数ですが、エラー項目を修正してからボタンを押してください。</p>
            </div>

            <div class="radio">
              <input id="radio01" type="radio" name="mailing_add" v-model="shopping.send_user_address" @click="sendUserAddress()" value="1">
              <label for="radio01">塾生登録している住所に送る</label><br>
              <input id="radio02" type="radio" name="mailing_add" v-model="shopping.send_user_address" @click="sendOtherAddress()" value="0">
              <label for="radio02">送り先を指定する</label>
            </div>

            <div v-show="shopping.send_user_address === 0 || shopping.send_user_address === '0'">
              <div class="input_set name">
                <div class="label">おなまえ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓</div>
                    <input type="text" maxlength="16" placeholder="例：山田" v-model="shopping.last_name"
                           @input="shopping.last_name = replaceHalfToFull(shopping.last_name)"
                           v-bind:class="{error: validation_errors.last_name}">
                    <ValidationErrors :errors="validation_errors.last_name" v-if="validation_errors.last_name"/>
                  </div>
                  <div class="field">
                    <div class="label">名</div>
                    <input type="text" maxlength="16" placeholder="例：太郎" v-model="shopping.first_name"
                           @input="shopping.first_name = replaceHalfToFull(shopping.first_name)"
                           v-bind:class="{error: validation_errors.first_name}">
                    <ValidationErrors :errors="validation_errors.first_name" v-if="validation_errors.first_name"/>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">フリガナ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓（全角カナ）</div>
                    <input type="text" maxlength="16" placeholder="例：ヤマダ" v-model="shopping.last_name_kana"
                           @input="shopping.last_name_kana = replaceHiraToKana(shopping.last_name_kana)"
                           v-bind:class="{error: validation_errors.last_name_kana}">
                    <ValidationErrors :errors="validation_errors.last_name_kana"
                                      v-if="validation_errors.last_name_kana"/>
                  </div>
                  <div class="field">
                    <div class="label">名（全角カナ）</div>
                    <input type="text" maxlength="16" placeholder="例：タロウ" v-model="shopping.first_name_kana"
                           @input="shopping.first_name_kana = replaceHiraToKana(shopping.first_name_kana)"
                           v-bind:class="{error: validation_errors.first_name_kana}">
                    <ValidationErrors :errors="validation_errors.first_name_kana"
                                      v-if="validation_errors.first_name_kana"/>
                  </div>
                </div>
              </div>

              <div class="input_set phone">
                <div class="label">電話番号<span>*</span></div>
                <p>（半角数字）</p>
                <input type="tel" v-model="shopping.phone_number"
                       @input="shopping.phone_number = replaceFullToHalf(shopping.phone_number)"
                       v-bind:class="{error: validation_errors.phone_number}" placeholder="例：09012345678"
                       value="09012345678">
                <ValidationErrors :errors="validation_errors.phone_number" v-if="validation_errors.phone_number"/>
              </div>

              <div class="input_set addr">
                <div class="label">郵便番号<span>*</span></div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="shopping.zip" v-bind:class="{error: validation_errors.zip}"
                           @input="shopping.zip = replaceFullToHalf(shopping.zip)"
                           name="zip"
                           placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.zip"
                                       v-if="validation_errors.zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名<span>*</span></div>
                <p>（選択して下さい ※海外には配送できません）</p>
                <PrefectureSelect :prefecture="shopping.prefecture" :only_japan="true" name="prefecture"
                                  v-bind:class="{error: validation_errors.prefecture}"
                                  v-on:changed="prefectureChanged"/>
                <validation-errors :errors="validation_errors.prefecture"
                                   v-if="validation_errors.prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村<span>*</span></div>
                <input type="text" v-model="shopping.address1" v-bind:class="{error: validation_errors.address1}"
                       @input="shopping.address1 = replaceHalfToFull(shopping.address1)"
                       placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.address1"
                                   v-if="validation_errors.address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地<span>*</span></div>
                <input type="text" v-model="shopping.address2" v-bind:class="{error: validation_errors.address2}"
                       @input="shopping.address2 = replaceHalfToFull(shopping.address2)"
                       class="address" placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.address2"
                                   v-if="validation_errors.address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="shopping.address3" v-bind:class="{error: validation_errors.address3}"
                       @input="shopping.address3 = replaceHalfToFull(shopping.address3)"
                       class="addr4" placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.address3"
                                   v-if="validation_errors.address3"></validation-errors>
              </div>
            </div>

            <div class="input_set mt20">
              <div class="label">備考欄</div>
              <textarea name="" placeholder="" v-bind:class="{error: validation_errors.memo}"
                        v-model="shopping.memo" maxlength="124"></textarea>
              <ValidationErrors :errors="validation_errors.memo"
                                v-if="validation_errors.memo"></ValidationErrors>
            </div>

            <div class="cts_box">
              <div class="btnBox mb40">
                <p class="alnC mb10">すべての項目の入力が完了しましたら<br class="sp">こちらのボタンを押してください。</p>
                <button type="submit" class="submit light arrow">ご注文内容の確認</button>
              </div>

              <div class="btnBox">
                <p class="alnC mb10">カートの中身を変更する場合は<br class="sp">こちらのボタンを押してください。</p>
                <a @click="$router.go(-1)" class="submit light skeleton arrow back">前のページにもどる</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import ValidationErrors from "@/components/ValidationErrors";
import PrefectureSelect from "@/components/PrefectureSelect";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
  },
  data() {
    return {
      fetch_data: null,
      shopping: null,
      validation_errors: {},
      errors: null,
    }
  },
  mounted() {
    // form token
    this.setFormToken('Shopping')

    if (!this.isUserSignedIn()) {
      // 非会員なら非会員用のお届け先ページへ
      this.$router.push({name: 'ShoppingRegisterNonMember'})
    } else {
      this.fetchData()
    }
  },
  methods: {
    pointPercent(article) {
      return article.add_points * 100 / article.price;
    },

    sendUserAddress() {
      this.shopping.email = this.current_user.email
      this.shopping.email_confirmation = this.shopping.email
      this.shopping.last_name = this.current_user.last_name
      this.shopping.first_name = this.current_user.first_name
      this.shopping.last_name_kana = this.current_user.last_name_kana
      this.shopping.first_name_kana = this.current_user.first_name_kana
      this.shopping.zip = this.current_user.zip
      this.shopping.prefecture = this.current_user.prefecture
      this.shopping.address1 = this.current_user.address1
      this.shopping.address2 = this.current_user.address2
      this.shopping.address3 = this.current_user.address3
      this.shopping.phone_number = this.current_user.phone_number
    },
    sendOtherAddress() {
      //this.shopping = this.fetch_data.shopping
      this.shopping.email = this.current_user.email
      this.shopping.email_confirmation = this.shopping.email
      this.shopping.last_name = ''
      this.shopping.first_name = ''
      this.shopping.last_name_kana = ''
      this.shopping.first_name_kana = ''
      this.shopping.zip = ''
      this.shopping.prefecture = ''
      this.shopping.address1 = ''
      this.shopping.address2 = ''
      this.shopping.address3 = ''
      this.shopping.phone_number = ''
    },

    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/shopping.json`)
          .then(response => {
            this.fetch_data = response.data
            this.shopping = response.data.shopping
            if (this.isUserSignedIn()) {
              if (!this.shopping.last_name) {
                this.shopping.email = this.current_user.email
                this.shopping.email_confirmation = this.shopping.email
                this.shopping.last_name = this.current_user.last_name
                this.shopping.first_name = this.current_user.first_name
                this.shopping.last_name_kana = this.current_user.last_name_kana
                this.shopping.first_name_kana = this.current_user.first_name_kana
                this.shopping.zip = this.current_user.zip
                this.shopping.prefecture = this.current_user.prefecture
                this.shopping.address1 = this.current_user.address1
                this.shopping.address2 = this.current_user.address2
                this.shopping.address3 = this.current_user.address3
                this.shopping.phone_number = this.current_user.phone_number
              } else if (this.shopping.email) {
                this.shopping.email_confirmation = this.shopping.email
              }
            }
            if (this.fetch_data.shopping_count === 0) {
              this.$router.push({name: 'ShoppingArticles'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    prefectureChanged(prefecture) {
      this.shopping.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.shopping.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.shopping.zip) {
        this.addressSearch(this.shopping.zip)
            .then(data => {
              this.shopping.prefecture = data.pref
              this.shopping.address1 = data.address
              this.$forceUpdate()
            })
      }
    },
    update(event) {
      event.preventDefault()
      this.axios
          .post(`${this.env.api_base_url}shop/update_shopping.json`, {
            user_shopping: this.shopping,
          })
          .then(() => {
            this.$router.push({name: 'ShoppingPayment'})
          })
          .catch((error) => {
            this.scrollTo('#form')
            if (error.response.data.errors) {
              this.validation_errors = error.response.data.validation_errors
              this.errors = true
            }
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
